<template>
  <div class="business">
    <div class="tit">
      {{ $t('reg_guidance_1') }}
    </div>
    <div class="tit-tip">
      {{ $t('reg_guidance_2') }}
    </div>
    <div class="business-cont">
      <div class="imgBox">
        <img class="img" src="@/assets/img/index/currenxieCrossPlatform.webp" />
      </div>
      <el-tag class="tag" size="small">{{ $t('reg_guidance_3') }}</el-tag>
      <div class="cont-ls">1. {{ $t('reg_guidance_4') }}</div>
      <div class="cont-ls">2. {{ $t('reg_guidance_5') }}</div>
      <el-button class="guidance-button" type="primary" @click="continues">
        {{ $t('reg_guidance_6') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { getUrlArgs } from '@/utils/tool.js'

export default {
  methods: {
    continues() {
      if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.$message({
          message: '请在手机浏览器操作',
          type: 'warning',
        })
        return
      }
      this.$router.push({
        name: 'Uploading',
        params: { email: getUrlArgs('email') },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.business {
  padding: 5%;

  .tit {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    line-height: 36px;
  }

  .tit-tip {
    font-size: 14px;
    color: #666;
    text-align: center;
  }
}

.business-cont {
  font-size: 14px;
  color: #333;
  margin-top: 35px;

  .imgBox {
    text-align: center;
  }

  .img {
    width: 150px;
    height: 140px;
    margin-bottom: 20px;
  }

  .tag {
    margin-bottom: 10px;
  }

  .cont-ls {
    line-height: 24px;
  }

  .guidance-button {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
